<template>
  <div class="references">
    <h2 v-if="reference" class="references__heading">Citations</h2>
    <hr>
    <ol class="references__list">
      <editor-content :editor="editor"/>
    </ol>
  </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import {generateHTML} from '@tiptap/html';
import StarterKit from "@tiptap/starter-kit";

export default {
  components: {
    EditorContent
  },
  data() {
    return {
      editor: null,
      reference: '',
      references: [],
    }
  },
  mounted() {
    this.getReferences();
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TextStyle,
        Link.configure({
          openOnClick: true,
        }),
      ],
      content: `${this.reference}`,
      editable: false,

    });
  },
  methods: {
    getReferences() {
      this.references = this.$store.getters["editor/getAllReferences"];
      let referenceHTML = [];
      if (this.references !== "") {
        this.references.forEach((val, i) => {
          let html = generateHTML(val.content, [
            StarterKit,
            TextStyle,
            Link.configure({
              openOnClick: false,
            }),
          ])
          referenceHTML.push(val.id + '.&nbsp;' + html);

        })
      }
      this.reference += '<ol>'
      referenceHTML.forEach((val, i) => {
        this.reference += `<li id="${i + 1}">`
        this.reference += val;
        this.reference += '</li>'
      })
      this.reference += '</ol>'
    },
  }
}
</script>

<style lang="scss">
.references {
  width: 100%;

  &__heading {
    font-size: 2.5rem;
    font-family: $font-primary-bold;
    margin-bottom: 1rem
  }

  hr {
    border: 1px solid #011E2991;
    opacity: .2;
  }

  &__list {
    list-style: none;

    ul {
      list-style: none;
    }

    li {
      list-style: none;
      float: left;
      width: 20%;
      margin: 2rem;
    }

    p {
      display: inline;
    }
  }
}
</style>

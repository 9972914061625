<template>
  <div class="preview-main">
    <div class="preview">
      <a-row>
        <a-col :md="5" :lg="5">
          <div class="preview__left">
            <a href="" class="link">Back to Discover</a>
            <article-author></article-author>
            <div class="reaction">
              <div class="likes">
                <i class="icon icon-thumbs_up"></i>
                <p>89</p>
              </div>
              <div class="likes">
                <i class="icon icon-chat"></i>
                <p>40</p>
              </div>
              <div class="likes">
                <i class="icon icon-bookmark2"></i>
                <p></p>
              </div>
            </div>
            <publishing-team></publishing-team>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="14" :lg="14">
          <the-article></the-article>
        </a-col>
        <a-col :md="5" :lg="5">
          <div class="preview__right">
            <the-collaborators></the-collaborators>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <the-references v-if="showReferences"></the-references>
      </a-row>
    </div>
  </div>
</template>
<script>
import ArticleAuthor from "../../ArticleReadComponents/ArticleAuthor.vue";
import TheArticle from "../../ArticleReadComponents/TheArticle.vue";
import TheCollaborators from "../../ArticleReadComponents/TheCollaborators.vue";
import PublishingTeam from "../../ArticleReadComponents/PublishingTeam.vue";
import TheReferences from "../../ArticleReadComponents/TheReferences.vue";
export default {
  components: {
    ArticleAuthor,
    TheArticle,
    TheCollaborators,
    PublishingTeam,
    TheReferences,
  },
  data(){
    return{
      showReferences: false,
    }
  },
  mounted(){
    this.showReferences = this.$store.getters["editor/getAllReferences"].length;
  }
};
</script>
<style lang="scss" scoped>
@include reset-css;
.preview-main {
  padding: 3.8rem 3.5rem;
  .preview {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: auto;
    scrollbar-color: $color-primary rgba(255, 255, 255, 0);
    scrollbar-width: thin;
    &__left {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      padding-top: 2rem;
      // position: fixed;
      // left: 8rem;
      @include respond(laptop-small) {
        padding: 0;
      }
      @include respond(phone-large) {
        display: none;
      }
      @include respond(tab-port) {
        display: none;
      }
      a {
        font-size: 1.6rem;
        font-family: $font-primary-semibold;
        color: #011e29;
      }
      .reaction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .likes {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          .icon {
            margin-right: 1.2rem;
            font-size: 2rem;
            opacity: 0.8;
            cursor: pointer;
          }
          p {
            font-size: 1.3rem;
            font-family: $font-secondary;
          }
        }
      }
      .glocal-adds {
        background-image: url(/img/glocal-add.ed9bbca3.png);
        height: auto;
        background-position: center;
        background-size: cover;
        padding: 3rem;
        // position: absolute;
        // top: 100%;
        border-radius: 0.5rem;
        @include respond(laptop-large) {
          padding: 2rem;
        }
        @include respond(laptop-medium) {
          padding: 2rem;
        }
        @include respond(laptop-small) {
          padding: 2rem;
        }

        &__logo {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .glocal-logo {
            width: 3.5rem;
            margin-right: 1rem;
            @include respond(laptop-medium) {
              width: 2.5rem;
            }
            @include respond(laptop-small) {
              width: 2.5rem;
            }

            img {
              width: 100%;
            }
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary-bold;
            color: $color-white;
          }
        }
        &__title {
          h2 {
            font-size: 2.2rem;
            font-family: $font-primary-bold;
            color: $color-white;
            padding-top: 1.3rem;
            @include respond(tab-land) {
              width: 13rem;
            }
            @include respond(laptop-medium) {
              font-size: 2.2rem;
              line-height: 2.5rem;
            }
            @include respond(laptop-small) {
              font-size: 1.8rem;
            }
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            padding: 1.5rem 0;
            color: $color-white;
            @include respond(tab-land) {
              width: 18rem;
            }
            @include respond(laptop-medium) {
              font-size: 1.3rem;
              padding: 1.5rem 0;
            }
            @include respond(laptop-small) {
              font-size: 1.3rem;
              padding: 0.8rem 0;
            }
          }
        }
        .description {
          font-size: 1.3rem;
          color: $color-white;
          font-family: $font-primary;
          padding-bottom: 4rem;
          @include respond(laptop-medium) {
            font-size: 1.1rem;
            padding-bottom: 2rem;
          }
          @include respond(laptop-small) {
            font-size: 1.1rem;
            padding-bottom: 2rem;
          }
        }
        a {
          font-size: 1.3rem;
          color: $color-white;
          font-family: $font-primary-bold;
          @include respond(laptop-medium) {
            font-size: 1.2rem;
          }
          @include respond(laptop-small) {
            font-size: 1.1rem;
          }
        }
      }
    }

    &__right {
      padding: 0 2rem;
      // margin-top: 14rem;
      // position: fixed;
      padding-top: 2rem;
      @include respond(laptop-small) {
        padding: 0;
      }
      @include respond(phone-large) {
        display: none;
      }
      @include respond(tab-port) {
        display: none;
      }
    }
  }
  .preview::-webkit-scrollbar {
    width: 0.5rem;
  }

  .preview::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.8rem;
  }

  .preview::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }
}
</style>
